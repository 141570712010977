<template>
    <section>
        <header-page :title="infoCurso.nombre" ruta-atras="mis-cursos.carpeta.ver" :params="params">
            <template v-if="false">
                <div class="col-xl-2 col-lg-4 px-0 d-middle ml-auto">
                    <div class="bg-red w-100 mx-2 text-white text-center br-12 py-2 px-4 cr-pointer tres-puntos" @click="eliminarCurso()">
                        Eliminar
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 px-0">
                    <router-link :to="{name: 'mis-cursos.editar.curso',params:{id_curso:idCurso}}">
                        <div class="bg-blue mx-2 text-white text-center br-12 py-2 px-4 cr-pointer tres-puntos">
                            Editar
                        </div>
                    </router-link>
                </div>
                <div class="col-auto px-0">
                    <div class="bg-general mx-2 text-white text-center br-12 py-2 px-5 cr-pointer tres-puntos">
                        Gestionar
                    </div>
                </div>
            </template>
        </header-page>
        <div class="custom-scroll overflow-auto pt-4 px-2" style="height:calc(100vh - 185px);">
            <div class="row mx-0">
                <div class="col-12 text-gris f-18">
                    {{ infoCurso.descripcion }}
                </div>
            </div>
            <div class="row mx-0 mt-4 mb-3">
                <div v-if="infoCurso.recursos_count > 0" class="col-auto">
                    <div class="bg-white text-general shadow py-2 br-12 px-3 f-600 cr-pointer" @click="crearInteraccion">
                        <i class="icon-cloud-download f-18" /> Descargar recursos
                    </div>
                </div>
                <div class="col-auto ml-auto">
                    <div class="bg-white text-general py-2 px-4 shadow f-600 br-12 cr-pointer" @click="mensajes()">
                        <el-badge :value="12" :hidden="true">
                            <i class="icon-message-reply-text f-18" />
                        </el-badge>
                        Mensajes
                    </div>
                </div>
                <router-link :to="{name: 'foro'}">
                    <div class="col-auto f-600 text-gris f-18 py-2 cr-pointer">
                        Foro 
                        <el-badge :value="12" :hidden="true">
                            <i class="icon-forum text-general f-18" />
                        </el-badge>
                    </div>
                </router-link>
            </div>            
            <div class="col-auto" />
            <div class="row mx-0 border-bottom px-2 pb-3">
                <router-link 
                v-for="(ruta, r) in tabs" 
                :key="r" 
                :to="{name: ruta.name }"
                :class="ruta.name == $route.name ? 'current-tab f-23' : 'tab f-20'"
                class="col-auto"
                >
                    {{ ruta.titulo }}
                </router-link>
            </div>
            <router-view />
        </div>
        <!-- Partials -->
        <modal-eliminar 
        ref="EliminarCurso" 
        titulo="¿Estas seguro de Eliminar este curso?" 
        alerta="Los datos y la información no podrán ser recuperados." 
        :eliminar="true"
        />
        <modal-mensajes ref="mensajes" />
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import Curso from "../../../services/cursos/ver_cursos";
export default {
    components: {
        modalMensajes: () => import('../partials/modalMensajes')
    },
    data(){
        return {
            params: {
                id_carpeta: null
            },
            loadingRecursos: false,
            infoCurso: {
                id: null,
                nombre: null,
                descripcion: null,
                id_carpeta: null,
                tipo: null,
                calificacion: null,
                recursos_count: 0,                
            }
        }
    },
    computed: {
        ...mapGetters({
            entregas: 'cursos/ver_cursos/entregas',
            hechas: 'cursos/ver_cursos/hechas'
        }),
        idCurso(){
            return this.$route.params.id_curso
        },
        tabs(){
            return  [
                {
                    titulo: 'Temas',
                    name: 'curso.temas'
                },
                {
                    titulo: `Entregas (${this.hechas}/${this.entregas})`,
                    name: 'curso.entregas'
                }
            ]
        }
    },
    mounted(){
        this.getCurso()  
        this.$store.dispatch('cursos/ver_cursos/actionObtenerAvanceUsuario', this.idCurso)
    },
    methods: {
        eliminarCurso(){
            this.$refs.EliminarCurso.toggle();
        },
        mensajes(){
            this.$refs.mensajes.toggle();
        },
        async crearInteraccion(){
            try {
                let params = {
                    tipo: 3,
                    id_curso: this.idCurso
                }        
                const { data: { data } } = await Curso.crearInteraccion(params)
                this.descargarRecursos()
                this.getCurso()
                this.$store.dispatch('cursos/ver_cursos/actionObtenerAvanceUsuario', this.idCurso)
            } catch (error){
                this.error_catch(error)
            }
        },
        async descargarRecursos(){
            try {
                let params = {
                    id_curso: this.idCurso
                }
                let data = document.querySelector('.el-loading-mask')
                
                this.loadingRecursos = this.$loading({
                    lock: false,
                    text: 'El archivo se está descargando, por favor no cierre o actualice ésta página. Este proceso puede tardar unos segundos.',
                    spinner: 'icon-spin6 rotating f-40',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let button = document.createElement('div');

                button.classList.add('px-3', 'py-2', 'text-center', 'row', 'justify-content-end');
                let leave = document.createElement('div');
                leave.textContent = 'Continuar Navegando'
                leave.classList.add('text-general', 'f-18', 'px-2', 'cr-pointer', 'shadow', 'py-2', 'br-12', 'bg-white', 'mr-4');
                leave.addEventListener('click', () => {
                    this.loadingRecursos.close()
                })
                button.appendChild(leave);
                this.loadingRecursos.$el.appendChild(button);
                const {data:datos} = await Curso.descargarRecursos(params);
                let link = document.createElement('a');
                link.href = datos.url;
                link.download = `Recursos de ${this.infoCurso.nombre}`;
                link.click()
            
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loadingRecursos.close()
            }
        },
        async getCurso(){
            try {
                const { data: { data } } = await Curso.getCurso(this.idCurso)
                this.infoCurso = data.curso
                this.params.id_carpeta = data.curso.id_carpeta
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-spin6:before{
  -webkit-animation: rotating 2s linear infinite;
}
/* .spin{
    color: red;
    animation-name: example;
    animation-duration: 4s;
}
@keyframes example {
  from {color: red;}
  to {color: yellow;}
} */
</style>